import { useProfileImage } from '@guider-global/azure-storage-hooks';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useSettings,
} from '@guider-global/sanity-hooks';
import { interpolate } from 'functions';
import { useLocalization } from 'hooks';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectAMatchView } from 'views';
import { DashboardNotificationsContext } from '../DashboardWrapper';
import { getRecipients } from 'utils/getRelationshipRecipients';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useUsers } from '@guider-global/front-end-hooks';

export function SelectAMatchContainer() {
  const { getProfileImage } = useProfileImage();
  const {
    programName,
    programSlug,
    isGuide,
    isGroupProgram,
    hasRelationships,
    relationshipId,
    membership,
    program,
    relationship,
    profile,
    hasOpenMatchingRelationship,
  } = useContext(DashboardNotificationsContext);

  const openMatchingGuide =
    profile?.roles?.includes('guide') && !hasOpenMatchingRelationship;
  const openMatchingTrainee =
    profile?.roles?.includes('trainee') && !hasOpenMatchingRelationship;

  // User
  const { users } = useUsers({});
  const user = users?.at(0);

  // Default image
  const { settings } = useSettings({
    localeCode: 'en_GB',
  });

  const defaultAvatar =
    buildSanityImageUrl({
      source: settings?.static_media?.relationships?.default_user_avatar ?? '',
    }) ?? '';

  // Own image
  const [otherPartyProfile] = getRecipients({
    role: isGuide ? 'guide' : 'trainee',
    relationship,
    userId: user?.id,
  });

  const otherUserImage =
    getProfileImage({
      profilePicture: otherPartyProfile?.picture,
      userPicture: otherPartyProfile?.userPicture,
    }) ?? '';

  const userImage =
    getProfileImage({
      profilePicture: profile?.picture,
      userPicture: user?.picture,
    }) ?? '';

  const maxSpots = program?.relationships?.group_settings?.max_trainees ?? 0;
  const spotsOccupied = relationship?.traineeProfiles?.length ?? 0;
  const spotsLeft = maxSpots - spotsOccupied;

  const navigate = useNavigate();

  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const viewProps = useMemo(() => {
    let title: string | undefined,
      description: string | undefined,
      primaryButtonLabel: string | undefined,
      secondaryButtonLabel: string | undefined,
      primaryButtonOnClick = () => {},
      secondaryButtonOnClick = () => {};

    if (isGuide || openMatchingGuide) {
      primaryButtonLabel =
        baseLanguage?.globals?.common?.edit_profile_button_label;
      primaryButtonOnClick = () =>
        openMatchingGuide
          ? navigate(`/profile/bio`)
          : navigate(`/programs/${programSlug}/manage/guide`);
      secondaryButtonLabel = baseLanguage?.globals?.common?.visit_learning_hub;
      secondaryButtonOnClick = () => navigate(`/learn`);
      if (!isGroupProgram) {
        if (membership?.requiresApproval) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_pending_approval?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_pending_approval?.description;
        } else if (!membership?.isPublished && !openMatchingGuide) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_hidden?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_hidden?.description;
        } else {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_published?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.individual_program
              ?.guide_membership_published?.description;
        }
      } else {
        if (!hasRelationships) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_create_group?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_create_group?.description;
          primaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_create_group?.create_group_button_label;
          primaryButtonOnClick = () =>
            navigate(`/programs/${programSlug}/join/guide`);
        } else if (membership?.requiresApproval) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_group_membership_pending_approval?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_group_membership_pending_approval?.description;
        } else if (!membership?.isPublished) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_group_membership_hidden?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_group_membership_hidden?.description;
          primaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_group_membership_hidden?.publish_profile_button_label;
          primaryButtonOnClick = () =>
            navigate(`/programs/${programSlug}/manage/guide`);
        } else {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_activate_group?.title;
          description = interpolate(
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_activate_group?.description,
            {
              groupMembers: spotsOccupied?.toString(),
              spotsLeft: spotsLeft?.toString(),
              maxSpots: maxSpots?.toString(),
            },
          );
          primaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_activate_group?.view_group_button_label;
          primaryButtonOnClick = () =>
            navigate(`/relationships/${relationshipId}`);
          secondaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.guide_activate_group?.edit_group_profile_button_label;
          secondaryButtonOnClick = () =>
            navigate(`/programs/${programSlug}/manage/guide`);
        }
      }
    } else {
      if (!isGroupProgram) {
        title =
          baseLanguage?.dashboard?.onboarding?.steps?.individual_program
            ?.trainee_select_a_match?.title;
        description =
          baseLanguage?.dashboard?.onboarding?.steps?.individual_program
            ?.trainee_select_a_match?.description;
        primaryButtonLabel =
          baseLanguage?.globals?.common?.find_a_match_button_label;
        primaryButtonOnClick = () =>
          openMatchingTrainee
            ? navigate(`/profile/matches/guide`)
            : navigate(`/programs/${programSlug}/join/trainee`);
      } else {
        if (!hasRelationships) {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_select_a_group?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_select_a_group?.description;
          primaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_select_a_group?.select_a_group_button_label;
          primaryButtonOnClick = () =>
            navigate(`/programs/${programSlug}/join/trainee/choose-group`);
        } else {
          title =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_wait_for_session?.title;
          description =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_wait_for_session?.description;
          primaryButtonLabel =
            baseLanguage?.dashboard?.onboarding?.steps?.group_program
              ?.trainee_wait_for_session?.view_group_button_label;
          primaryButtonOnClick = () =>
            navigate(`/relationships/${relationshipId}`);
        }
      }
    }
    return {
      title,
      description,
      primaryButtonLabel,
      primaryButtonOnClick,
      secondaryButtonLabel,
      secondaryButtonOnClick,
    };
  }, [
    isGuide,
    openMatchingGuide,
    baseLanguage?.globals?.common?.edit_profile_button_label,
    baseLanguage?.globals?.common?.visit_learning_hub,
    baseLanguage?.globals?.common?.find_a_match_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_pending_approval?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_pending_approval?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_hidden?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_hidden?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_published?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.guide_membership_published?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.trainee_select_a_match?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.individual_program
      ?.trainee_select_a_match?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_create_group?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_create_group?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_create_group?.create_group_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_group_membership_pending_approval?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_group_membership_pending_approval?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_group_membership_hidden?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_group_membership_hidden?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_group_membership_hidden?.publish_profile_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_activate_group?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_activate_group?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_activate_group?.view_group_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.guide_activate_group?.edit_group_profile_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_select_a_group?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_select_a_group?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_select_a_group?.select_a_group_button_label,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_wait_for_session?.title,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_wait_for_session?.description,
    baseLanguage?.dashboard?.onboarding?.steps?.group_program
      ?.trainee_wait_for_session?.view_group_button_label,
    isGroupProgram,
    navigate,
    programSlug,
    membership?.requiresApproval,
    membership?.isPublished,
    hasRelationships,
    spotsOccupied,
    spotsLeft,
    maxSpots,
    relationshipId,
    openMatchingTrainee,
  ]);

  return (
    <SelectAMatchView
      otherUserAvatar={otherUserImage ?? defaultAvatar}
      userAvatar={userImage}
      programLabel={programName}
      heading={viewProps?.title}
      description={viewProps?.description}
      primaryButtonLabel={viewProps?.primaryButtonLabel}
      primaryButtonOnClick={viewProps?.primaryButtonOnClick}
      secondaryButtonLabel={viewProps?.secondaryButtonLabel}
      secondaryButtonOnClick={viewProps?.secondaryButtonOnClick}
    />
  );
}
